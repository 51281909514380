<template>
  <v-container fluid>
    <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title class="pa-4 accent">
          <v-btn :to="{ path: '/palestras' }" small exact fab text class="mr-2">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-icon color="primary" left>mdi-account-group-outline</v-icon>
          {{ palestra.descricao | excerpt(90) }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="">
          <h3 class="secondary--text text-h5 font-weight-medium my-6">
            1.Dados da Palestra
          </h3>
          <v-row>
            <v-col cols="12" md="3" class="mb-10">
              <v-card flat>
                <v-img v-if="destaque" :src="destaque" contain></v-img>

                <v-img
                  v-else
                  :src="require('@/assets/destaque_placeholder.jpg')"
                  contain
                ></v-img>

                <v-btn
                  fab
                  dark
                  x-small
                  absolute
                  bottom
                  right
                  @click="onButtonClick"
                >
                  <input
                    accept="image/*"
                    type="file"
                    class="d-none"
                    ref="uploader"
                    @change="onFileChanged"
                  />
                  <v-icon>mdi-image</v-icon>
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" md="9">
              <v-row dense class="px-4 mt-4">
                <!-- Descrição -->
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="palestra.descricao"
                    label="Descrição"
                    dense
                    required
                    :rules="formRules"
                  ></v-text-field>
                </v-col>
                <!-- Descrição Detalhada -->
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="palestra.descricao_detalhada"
                    label="Descrição detalhada"
                    dense
                  ></v-textarea>
                </v-col>
                <!-- Data Palestra -->
                <v-col cols="12" md="5" class="d-flex flex-column">
                  Dia da Palestra
                  <v-btn-toggle
                    mandatory
                    color="xbColor"
                    v-model="palestra.data"
                    group
                  >
                    <v-btn value="2024-03-01" height="50px"> 01 </v-btn>
                    <v-btn value="2024-03-02" height="50px"> 02 </v-btn>
                    <v-btn value="2024-03-03" height="50px"> 03 </v-btn>
                  </v-btn-toggle>
                </v-col>

                <!-- Exibir Palestra no App -->
                <v-col cols="12" md="3">
                  {{
                    palestra.exibir_app === true
                      ? "Exibir no app"
                      : "Não exibir no app"
                  }}

                  <v-switch
                    :false-value="false"
                    :true-value="true"
                    color="xbColor"
                    class="ma-0"
                    v-model="palestra.exibir_app"
                  ></v-switch>
                </v-col>

                <!-- Permitir Avaliações -->
                <v-col cols="12" md="3">
                  {{
                    palestra.liberar_avaliacao === true
                      ? "Permitir avaliações"
                      : "Não permitir avaliações"
                  }}

                  <v-switch
                    :false-value="false"
                    :true-value="true"
                    color="xbColor"
                    class="ma-0"
                    v-model="palestra.liberar_avaliacao"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-divider class="my-6"></v-divider>
          <h3 class="secondary--text text-h5 font-weight-medium my-6 ml-1">
            2.Dados do Palestrante
          </h3>
          <v-row dense>
            <v-col cols="12" md="2">
              <v-card class="mb-6" flat>
                <v-img v-if="avatar" :src="avatar" contain class="ml-4"></v-img>
                <v-img
                  v-else
                  class="ml-4"
                  :src="require('@/assets/avatar_placeholder.png')"
                  contain
                ></v-img>
                <v-btn
                  fab
                  dark
                  x-small
                  absolute
                  bottom
                  right
                  @click="onButtonClick2"
                >
                  <input
                    accept="image/*"
                    type="file"
                    class="d-none"
                    ref="uploader2"
                    @change="onFileChanged2"
                  />
                  <v-icon>mdi-image</v-icon>
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="12" md="10">
              <v-row class="pl-4">
                <!-- Nome Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante"
                    label="Nome"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Ramo Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_ramoatividade"
                    label="Ramo Atividade"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Instagram Palestrante -->
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="palestra.palestrante_instagram"
                    label="Instagram"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Facebook Palestrante -->
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="palestra.palestrante_facebook"
                    label="Facebook"
                    dense
                  ></v-text-field>
                </v-col>
                <!-- email Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_email"
                    label="Email"
                    dense
                  ></v-text-field>
                </v-col>
                <!-- Site Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_site"
                    label="Site"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Youtube Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_canalyoutube"
                    label="Canal Youtube"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Links Palestrante -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="palestra.palestrante_links"
                    label="Links"
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Palestrante Curriculo -->
                <v-col cols="12">
                  <v-textarea
                    v-model="palestra.palestrante_curriculum"
                    label="Minibio"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <!-- Arquivos Palestras -->
            <v-col cols="12" md="12" class="px-0">
              <v-divider class="mt-6"></v-divider>
              <v-card flat>
                <v-card-title class="px-0">
                  <h3 class="secondary--text text-h5 font-weight-medium">
                    3.Arquivos da Palestra
                  </h3>
                  <v-spacer></v-spacer>
                </v-card-title>

                <v-row>
                  <v-col cols="12" md="4">
                    <v-card class="mb-6" flat>
                      <p class="text-center text-subtitle-1">
                        Resumo da Palestra
                      </p>
                      <div class="d-flex justify-center align-center mt-6 mb-6">
                        <v-btn
                          :href="resumo_palestra ? resumo_palestra : ''"
                          target="_blank"
                          :download="resumo_palestra ? false : true"
                          height="90px"
                          :disabled="resumo_palestra ? false : true"
                          text
                        >
                          <div class="d-block">
                            <div class="d-flex justify-center align-center">
                              <v-icon size="40">
                                {{
                                  !resumo_palestra
                                    ? "mdi-file-multiple-outline"
                                    : "mdi-file-document-multiple-outline"
                                }}
                              </v-icon>
                            </div>
                            <div
                              class="d-flex justify-center align-center mt-4"
                            >
                              <span>
                                {{
                                  !resumo_palestra
                                    ? "Adicione o documento"
                                    : "Visualizar"
                                }}
                              </span>
                            </div>
                          </div>
                        </v-btn>
                      </div>

                      <div
                        class="d-flex justify-center align-center"
                        :class="!resumo_palestra ? 'mt-6' : ''"
                      >
                        <v-btn
                          color="primary"
                          small
                          @click="onResumoPalestraClick"
                        >
                          <input
                            accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file"
                            class="d-none"
                            ref="resumo_palestra"
                            @change="onResumoPalestraChanged"
                          />
                          <v-icon left>mdi-paperclip</v-icon>
                          <span>
                            {{ !resumo_palestra ? "Adicionar" : "Alterar" }}
                          </span>
                        </v-btn>
                        <span v-if="resumoUpload" class="ml-2">
                          {{ resumoUpload.name }}
                        </span>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card class="mb-6" flat>
                      <p class="text-center text-subtitle-1">Mapa Mental</p>
                      <div class="d-flex justify-center align-center mt-6 mb-6">
                        <v-btn
                          :href="mapa_mental ? mapa_mental : ''"
                          target="_blank"
                          :download="mapa_mental ? false : true"
                          height="90px"
                          :disabled="mapa_mental ? false : true"
                          text
                        >
                          <div class="d-block">
                            <div class="d-flex justify-center align-center">
                              <v-icon size="40">
                                {{
                                  !mapa_mental
                                    ? "mdi-file-multiple-outline"
                                    : "mdi-file-document-multiple-outline"
                                }}
                              </v-icon>
                            </div>
                            <div
                              class="d-flex justify-center align-center mt-4"
                            >
                              <span>
                                {{
                                  !mapa_mental
                                    ? "Adicione o documento"
                                    : "Visualizar"
                                }}
                              </span>
                            </div>
                          </div>
                        </v-btn>
                      </div>

                      <div
                        class="d-flex justify-center align-center"
                        :class="!mapa_mental ? 'mt-6' : ''"
                      >
                        <v-btn color="primary" small @click="onMapaMentalClick">
                          <input
                            accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file"
                            class="d-none"
                            ref="mapa_mental"
                            @change="onMapaMentalChanged"
                          />
                          <v-icon left>mdi-paperclip</v-icon>
                          <span>
                            {{ !mapa_mental ? "Adicionar" : "Alterar" }}
                          </span>
                        </v-btn>
                        <span v-if="mapaMentalUpload" class="ml-2">
                          {{ mapaMentalUpload.name }}
                        </span>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card class="mb-6" flat>
                      <p class="text-center text-subtitle-1">
                        Documento Complementar
                      </p>
                      <div class="d-flex justify-center align-center mt-6 mb-6">
                        <v-btn
                          :href="doc_complementar ? doc_complementar : ''"
                          target="_blank"
                          :download="doc_complementar ? false : true"
                          height="90px"
                          :disabled="doc_complementar ? false : true"
                          text
                        >
                          <div class="d-block">
                            <div class="d-flex justify-center align-center">
                              <v-icon size="40">
                                {{
                                  !doc_complementar
                                    ? "mdi-file-multiple-outline"
                                    : "mdi-file-document-multiple-outline"
                                }}
                              </v-icon>
                            </div>
                            <div
                              class="d-flex justify-center align-center mt-4"
                            >
                              <span>
                                {{
                                  !doc_complementar
                                    ? "Adicione o documento"
                                    : "Visualizar"
                                }}
                              </span>
                            </div>
                          </div>
                        </v-btn>
                      </div>

                      <div
                        class="d-flex justify-center align-center"
                        :class="!doc_complementar ? 'mt-6' : ''"
                      >
                        <v-btn
                          color="primary"
                          small
                          @click="onDocComplementarClick"
                        >
                          <input
                            accept="image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file"
                            class="d-none"
                            ref="doc_complementar"
                            @change="onDocComplementarChanged"
                          />
                          <v-icon left>mdi-paperclip</v-icon>
                          <span>
                            {{ !doc_complementar ? "Adicionar" : "Alterar" }}
                          </span>
                        </v-btn>
                        <span v-if="docComplementarUpload" class="ml-2">
                          {{ docComplementarUpload.name }}
                        </span>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>

                <v-card-actions class="pt-6 pb-3">
                  <p class="text-caption">
                    Formatos permitidos : .jpg, .png, .txt, .xls, .xlsx, .pdf,
                    .doc, .docx, .odt.
                  </p>
                  <v-spacer></v-spacer>
                  <p class="text-caption">Tamanho máximo 15mb</p>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions class="pt-6">
            <v-btn text :to="{ path: '/palestras' }" exact color="primary">
              <v-icon class="mr-2" left>mdi-arrow-left</v-icon>
              Voltar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="updatePalestra"
              :disabled="!validForm"
              color="save"
              class="white--text"
            >
              <v-icon left>mdi-content-save</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { putPalestra, one as fetchPalestra } from "@/api/admin/palestras.js";
import { inputRequired } from "@/plugins/utils.js";

export default {
  name: "Palestra",

  data() {
    return {
      tab: 0,
      palestra: {},
      palestra_original: {},
      destaque: null,
      destaqueUpload: null,
      avatar: null,
      avatarUpload: null,
      loading: false,
      menuHora: false,
      menuHora2: false,
      hoverFile: false,
      hoverFile2: false,
      validForm: true,
      time: "12:00",
      time2: "12:00",
      formRules: [inputRequired],
      resumoUpload: null,
      resumo_palestra: null,
      mapaMentalUpload: null,
      mapa_mental: null,
      docComplementarUpload: null,
      doc_complementar: null,
      block: false,
    };
  },

  components: {},

  computed: {
    palestra_id() {
      return this.$route.params.palestraId;
    },
  },

  watch: {},

  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onButtonClick2() {
      this.$refs.uploader2.click();
    },
    onResumoPalestraClick() {
      this.$refs.resumo_palestra.click();
    },
    onMapaMentalClick() {
      this.$refs.mapa_mental.click();
    },
    onDocComplementarClick() {
      this.$refs.doc_complementar.click();
    },

    onFileChanged(e) {
      const file = e.target.files[0];
      this.destaqueUpload = file;
      this.palestra.img_destaque = !this.palestra.img_destaque ? "" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.destaque = event.target.result;
      };
    },

    onFileChanged2(e) {
      const file = e.target.files[0];
      this.avatarUpload = file;
      this.palestra.palestrante_avatar = !this.palestra.palestrante_avatar
        ? ""
        : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.avatar = event.target.result;
      };
    },

    onResumoPalestraChanged(e) {
      const file = e.target.files[0];
      this.resumoUpload = file;
      this.palestra.resumo_palestra = !this.palestra.resumo_palestra
        ? ""
        : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.resumo_palestra = event.target.result;
      };
    },
    onMapaMentalChanged(e) {
      const file = e.target.files[0];
      this.mapaMentalUpload = file;
      this.palestra.mapa_mental = !this.palestra.mapa_mental ? "" : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.mapa_mental = event.target.result;
      };
    },
    onDocComplementarChanged(e) {
      const file = e.target.files[0];
      this.docComplementarUpload = file;
      this.palestra.documento_complementar = !this.palestra
        .documento_complementar
        ? ""
        : null;
      const vue = this;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        vue.doc_complementar = event.target.result;
      };
    },

    validaFile(item) {
      if (item.size >= 15000000) {
        this.$toast.error("O tamanho maximo do arquivo permitido é 15Mb");
        this.block = true;
        return false;
      } else if (
        item.type.substr(-5) != "/jpeg" &&
        item.type.substr(-4) != "/png" &&
        item.type.substr(-4) != "/txt" &&
        item.type.substr(-4) != "/xls" &&
        item.type.substr(-6) != ".sheet" &&
        item.type.substr(-4) != "/pdf" &&
        item.type.substr(-4) != "/doc" &&
        item.type.substr(-5) != ".text" &&
        item.type.substr(-9) != ".document"
      ) {
        this.$toast.error("Formato não permitido");
        this.block = true;
        return false;
      }
      return true;
    },

    updatePalestra() {
      if (
        JSON.stringify(this.palestra) === JSON.stringify(this.palestra_original)
      ) {
        this.$toast.error("Por favor, faça uma mudança antes de salvar!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;

        this.loading = true;
        const palestra = new FormData();

        if (this.destaqueUpload) {
          palestra.append(
            "img_destaque",
            this.destaqueUpload,
            this.destaqueUpload.name
          );
        }
        if (this.avatarUpload) {
          palestra.append(
            "palestrante_avatar",
            this.avatarUpload,
            this.avatarUpload.name
          );
        }

        if (this.resumoUpload) {
          if (this.validaFile(this.resumoUpload)) {
            palestra.append(
              "resumo_palestra",
              this.resumoUpload,
              this.resumoUpload.name
            );
          }
        }
        if (this.mapaMentalUpload) {
          if (this.validaFile(this.mapaMentalUpload)) {
            palestra.append(
              "mapa_mental",
              this.mapaMentalUpload,
              this.mapaMentalUpload.name
            );
          }
        }
        if (this.docComplementarUpload) {
          if (this.validaFile(this.docComplementarUpload)) {
            palestra.append(
              "documento_complementar",
              this.docComplementarUpload,
              this.docComplementarUpload.name
            );
          }
        }
        if (this.block === false) {
          let palestra_2 = {};
          palestra_2.descricao = this.palestra.descricao;
          palestra_2.descricao_detalhada = this.palestra.descricao_detalhada;
          palestra_2.data = this.palestra.data;
          palestra_2.hora = this.palestra.hora;
          palestra_2.exibir_app = this.palestra.exibir_app;
          palestra_2.liberar_avaliacao = this.palestra.liberar_avaliacao;
          palestra_2.msg_aviso = this.palestra.msg_aviso;
          palestra_2.msg_aviso_time = this.palestra.msg_aviso_time;
          palestra_2.palestrante = this.palestra.palestrante;
          palestra_2.palestrante_curriculum =
            this.palestra.palestrante_curriculum;
          palestra_2.palestrante_instagram =
            this.palestra.palestrante_instagram;
          palestra_2.palestrante_facebook = this.palestra.palestrante_facebook;
          palestra_2.palestrante_email = this.palestra.palestrante_email;
          palestra_2.palestrante_ramoatividade =
            this.palestra.palestrante_ramoatividade;
          palestra_2.palestrante_site = this.palestra.palestrante_site;
          palestra_2.palestrante_canalyoutube =
            this.palestra.palestrante_canalyoutube;
          palestra_2.palestrante_links = this.palestra.palestrante_links;

          for (let key in palestra_2) {
            if (
              palestra_2[key] !== null &&
              palestra_2[key] !== undefined &&
              palestra_2[key] !== ""
            ) {
              palestra.append(key, palestra_2[key]);
            }
          }

          putPalestra(this.palestra_id, palestra)
            .then((response) => {
              if (response.status === 200) {
                this.$toast.success("Atualizado com sucesso");
                this.$router.push({ path: "/palestras" });
                this.getPalestras();
                this.loading = false;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },
    getPalestra() {
      this.loading = true;
      fetchPalestra(this.palestra_id)
        .then((response) => {
          this.palestra = response;
          this.palestra_original = { ...response };
          this.avatar = this.palestra.palestrante_avatar;
          this.destaque = this.palestra.img_destaque;
          this.resumo_palestra = this.palestra.resumo_palestra;
          this.mapa_mental = this.palestra.mapa_mental;
          this.doc_complementar = this.palestra.documento_complementar;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getPalestra();
  },
};
</script>

<style lang="scss" scoped></style>
